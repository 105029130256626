import {DropdownBlock} from '/components/dropdown-block/dropdown-block';
import {CopyButton} from '/components/utils/copy-button';


export class PermalinkDropdown {
  constructor(container) {
    this.permalinkContainer = container;
    this.permalinkDropdownTrigger = this.permalinkContainer.children('.trigger');
    this.permalinkDropdownContainer = this.permalinkContainer.children('.dropdown');
    this.initPermalink();
  }

  initPermalink() {
    if (!this.permalinkContainer[0]) {
      this.permalinkDropdown = null;
      return;
    }

    const dropdownContainer = this.permalinkDropdownContainer.removeAttr('hidden');

    this.permalinkDropdown = new DropdownBlock({
      target: this.permalinkDropdownTrigger,
      container: dropdownContainer,
      toggleOnHover: false,
      wrap: false
    });

    this.permalinkTextNode = dropdownContainer.find('.permalink-text');

    this.permalinkCopyButton = new CopyButton({
      trigger: dropdownContainer.find('.permalink-copy-button'),
      target: this.permalinkTextNode,
      message: 'Permalink successfully copied.'
    });

    // Select permalink text when dropdown opens
    dropdownContainer.on('dropdown-active', () => this.permalinkTextNode.select());
  }
}