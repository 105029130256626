import Noty from 'noty';

const Notification = {
  options: {
    layout: 'center',
    theme: 'uswdsTheme',
    animation: {
      // See `components/animations.scss`.
      open: 'animated fade-in-down',
      close: 'animated fade-out-up'
    },
    callbacks: {
      onShow: function() {
        this.layoutDom.classList.add('notification');
        this.layoutDom.setAttribute('role', 'alert');
        this.layoutDom.setAttribute('aria-live', 'assertive');
        this.barDom.classList.add('uswdsTheme');
      }
    },
    timeout: 5000
  },
  notify: function(text, type) {
    new Noty(Object.assign({text: text, type: type}, Notification.options)).show();
  },
  info: (text) => Notification.notify(text, 'information'),
  warning: (text) => Notification.notify(text, 'warning'),
  error: (text) => Notification.notify(text, 'error')
};

export {Notification};