/* global $ */
/* global pmc */
import './article.scss'
import { Sidebar } from "/components/sidebar/sidebar";

window.ncbi = window.ncbi || {};
window.ncbi.pmc = window.ncbi.pmc || {};

window.ncbi.pmc.articlePage = {
  init({ pageURL, citeCookieName }){
    this.sidebar = new Sidebar($('.pmc-sidebar'), pageURL, citeCookieName);
  },

};