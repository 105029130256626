import {Notification} from "/components/notifications/notifications";
import { setSecureCookieIfPossible, readCookie } from '/components/utils/set-secure-cookie-if-possible';
import { PermalinkDropdown } from "./social-sharing-permalink";
import { CiteBox } from "ctxp";

const loadingTemplate = `
    <div class="pmc-sidebar__loading">
        <div class=\"lds-ellipsis\"><div></div><div></div><div></div><div></div></div>
    </div>
`;

const repeatTemplate = `
    <div class="pmc-sidebar__resources__repeat">
        Something Went Wrong. Please Try Again:
        <button><i class="fa fa-repeat" aria-hidden="true"></i></button>
    </div>
`;
/**
 * Control to initialize and manage functionalities on article sidebar
 */
export class Sidebar {

    constructor(container, pageURL, citeCookieName) {
        this.container = container;
        this.pageURL = pageURL;
        this.citeCookieName = citeCookieName;
        this.initFavoritesButton();
        this.initPermLink();
        this.initCiteButton();
        this.initResources();
    }

    initCiteButton() {
        const citeContainer = $('.citation-dialog');
        const citeTrigger = $('.citation-dialog-trigger');
        const defaultCiteFormat = readCookie(this.citeCookieName) || citeTrigger.data('citation-style');
        citeTrigger.data('citation-style', defaultCiteFormat);
        this.citeBox = new CiteBox(citeContainer, citeTrigger);
        citeContainer.on('copy-event', (
            e,
            messageLevel,
            messageAction,
            message
        ) => {
            if($(e.target).is(":visible")) {
                Notification.notify(message, 'information');
            }
        });
        citeContainer.on('change-event', (
            e,
            messageLevel,
            messageAction,
            selectedStyle
        ) => {
             setSecureCookieIfPossible(
                this.citeCookieName, selectedStyle, {'expires': 365 * 5}
             );
        });
    }

    initFavoritesButton() {
        const collectionButtons = this.container.find('.collections-button-container');
        /**
         * list of collection button sort by visible property so that after successful authentication,
         * the visible first intercepts the fragment in url and not the first in the DOM.
         * It was done because the fragment in url is removed when the CollectionDialog method show is called.
         */
        collectionButtons.sort(function(itemA,itemB){
            return Number($(itemB).is(":visible"))-Number($(itemA).is(":visible"));
        }).each(function(index, item) {
            new window.exports.CollectionsButton($(item));
        });
    }

    initPermLink() {
        this.permalinkDropdown = new PermalinkDropdown(
          this.container.find('.share-permalink')
        );
    }

    callGetResource($target, sourceUrl) {
         $target.html(loadingTemplate);

         Sidebar.dataController.getResource(sourceUrl)
        .done((resourceHTML) => {
            $target.html(resourceHTML);
            $('.pmc-sidebar__resources__ext_link a', $target).attr('data-ga-label', this.pageURL);
        })
        .fail((message) => {
            $target.html(repeatTemplate);
            $('.pmc-sidebar__resources__repeat button', $target).click(
                () => this.callGetResource($target, sourceUrl)
            );
        });
    }

    initResources(){
        const attrObserve = 'aria-hidden';
        const resourceContents = this.container.find('.pmc-sidebar__resources .usa-accordion-content');
        this.container.find('.pmc-sidebar__resources .usa-accordion-button').click((e) => {
            const $button = $(e.target);
            const isAriaExpanded = $button.attr('aria-expanded') === 'true';
            const action = isAriaExpanded? $button.data('action-open'): $button.data('action-close');
            $button.attr('data-ga-action', action);
        });
        const observer = new MutationObserver((mutations) => {
              mutations.forEach((mutation) =>  {
                    if (mutation.type !== "attributes" || mutation.attributeName !== attrObserve) {
                       return;
                    }
                    const $target = $(mutation.target);
                    const isHidden = $target.attr(attrObserve) === 'true';
                    const sourceUrl = $target.data('source-url');
                    const isEmpty = mutation.target.innerHTML.trim() === "";
                    if (isHidden || !isEmpty || !sourceUrl) {
                        return;
                    }
                    this.callGetResource($target, sourceUrl);
              });
        });
        resourceContents.each((index, elem) =>
            observer.observe(
                elem,
                {attributes: true, attributeOldValue: true, attributeFilter:[attrObserve]}
            )
        );
    }
}


Sidebar.dataController = {
  resourcesLoaded: {},
  resources: {},

  /**
   * Loads resource html from web service once, saves them into property
   * to be used next time.
   * @returns jQuery.promise(citationText)
   */
  getResource(sourceUrl) {
    const dfd = $.Deferred();

    // resource already loaded
    if (this.resourcesLoaded[sourceUrl]) {
      return dfd.resolve(this.resources[sourceUrl]).promise();
    }

    // resource are not yet loaded
    $.get(sourceUrl).done((resourceHTML) => {
      this.resources[sourceUrl] = resourceHTML;
      this.resourcesLoaded[sourceUrl] = true;
      dfd.resolve(resourceHTML).promise();
    }).fail(() => {
      dfd.reject('Loading of citation failed.');
    });

    return dfd.promise();
  },

};