import $ from 'jquery';

/**
 * Simple dropdown block which appears on hover / click.
 *
 * It may be a (context) menu, a tooltip / hint, etc.
 *
 * Usage example (for dropdown menu):
 *
 * ```html
 * <div class="my-menu" id="my-menu">
 *   <div class="title">Menu title</div>
 *   <!-- Optional "close" button -->
 *   <a href="#" class="close-button">[x]</a>
 *   <!-- block contents -->
 * </div>
 * ```
 *
 * ```js
 * let block = new DropdownBlock({
 *   target: $('#my-menu-trigger'),
 *   container: $('#my-menu')
 * });
 * ```
 */
export class DropdownBlock {
  /**
   * @param {(HTMLElement|jQuery)} target - Element which will trigger the menu
   *                                        (it will appear below it by default).
   * @param {(HTMLElement|jQuery)} container - Dropdown container element.
   * @param {string} className - CSS class of the element _wrapped around_ the `target` element.
   * @param {boolean} toggleOnHover - Toggle the dropdown on hover?
   * @param {boolean} toggleOnClick - Toggle the dropdown on click?
   */
  constructor({
    target,
    container,
    wrapperClassName = 'dropdown-block',
    dropdownClassName = 'dropdown-container',
    wrap = true,
    toggleOnHover = true,
    toggleOnClick = true,
    flipCloseToViewportEnd = false,
    targetClassName = null
  }) {
    this.target = $(target);
    this.container = $(container).addClass(dropdownClassName);
    this.targetClassName = targetClassName;

    // By default we wrap the target input element with a `<div class="dropdown-block">`.
    // But we can skip this step and just add `dropdown-block` class to the input's parent element.
    if (wrap) {
      this.wrapper = this.target.wrap('<div>').parent();
      this.container.appendTo(this.wrapper);
    } else {
      this.wrapper = null;
    }

    this.target.parent().addClass(wrapperClassName);
    this.toggleOnHover = toggleOnHover;
    this.toggleOnClick = toggleOnClick;
    this.shouldFlip = flipCloseToViewportEnd;
    this.initAppearance();
  }

  initAppearance() {
    // Toggle the dropdown visibility on hover.
    if (this.toggleOnHover) {
      let _closing;

      // Show the dropdown by hovering `this.target`.
      // Also, reset the "closing" timeout when hovering the dropdown container.
      this.target.add(this.container).on('mouseenter', () => {
        clearTimeout(_closing);
        this.show();
      });

      // Start "closing" timeout when mouse has "left" `this.target` or `this.container`.
      this.target.add(this.container).on('mouseleave', () => {
        _closing = setTimeout(() => {
          this.container.removeClass('active');
        }, 250);
      });
    }

    // Toggle the dropdown visibility on click.
    if (this.toggleOnClick) {
      // Show/hide the dropdown by clicking `this.target` element.
      this.target.on('click', (e) => this.toggleOnClickHandler(e));

      // Hide the dropdown when clicked "outside of it".
      $('body').on('click', (e) => {
        const $target = $(e.target);

        if (
          !$target.closest(this.target).length &&
          !$target.closest(this.container).length
        ) {
          this.hide();
        }
      });
    }

    // Hide the dropdown when tabbing out from last focusable element
    this.container.on('keydown', (e) => {
      const focusableEls = $(e.currentTarget).find(
        'a:visible, button:visible, textarea, input[type="text"], ' +
          'input[type="radio"], input[type="checkbox"], select'
      );
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const firstFocusableEl = focusableEls[0];
      if ((!e.shiftKey && e.keyCode === 9 && document.activeElement === lastFocusableEl) ||
        (e.shiftKey && e.keyCode === 9 && document.activeElement === firstFocusableEl)
      ) {
        // tab was pressed
        this.hide();
      } else if (e.key.toLowerCase() === 'escape') {
        this.target.focus();
      }
    });

    // Hide the dropdown when ESC key pressed.
    $(window).on('keydown', (e) => {
      if (e.key.toLowerCase() === 'escape') {
        this.hide();
      }
    });
  }

  toggleOnClickHandler(e) {
    e.preventDefault();

    if (this.isDisplayed(e)) {
      this.hide();
    } else {
      if (this.shouldFlip) {
        // viewport height - trigger button bottom Y coord < dropdownHeight + margin => flip
        const dropdownHeight = this.container.height();
        const viewportHeight = window.innerHeight;
        const triggerBottom = e.currentTarget.getBoundingClientRect().bottom;
        const dropdownMargin = 30; // px
        if (viewportHeight - triggerBottom < dropdownHeight + dropdownMargin) {
          this.container.addClass('flipped');
        } else {
          this.container.removeClass('flipped');
        }
      }
      this.show();
    }
  }

  isDisplayed(e) {
    if (this.container.hasClass('active')) {
      // prevent closing when opening same instance with different trigger (cite/share)
      if (this.targetClassName && $(e.currentTarget).hasClass(this.targetClassName)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  show() {
    this.target.addClass('active');
    this.target.attr('aria-expanded', 'true');
    this.container.addClass('active');
    this.container.trigger('dropdown-active');
    this.container.attr('aria-hidden', 'false');
  }

  hide() {
    this.target.removeClass('active');
    this.target.attr('aria-expanded', 'false');
    this.container.removeClass('active');
    this.container.trigger('dropdown-not-active');
    this.container.attr('aria-hidden', 'true');
  }
}