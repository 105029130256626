import {Notification} from '/components/notifications/notifications';


export class CopyButton {
  constructor({trigger, target, message}) {
    this.trigger = trigger;
    this.target = target;
    this.message = message;
    this.initCopyButton();
  }

  /**
   * On click on copy button selected text should be copied to clipboard.
   */
  initCopyButton() {
    this.trigger.on('click', () => this.tryCopy());
  }

  /**
  * Try to copy text. Handle success/fail messages and logging.
  */
  tryCopy() {
    let copied = false;

    try {
      this.selectTargetValue();
      copied = document.execCommand('copy');
      // deselect highlighted text after copy is complete
      window.getSelection().removeAllRanges();
    } catch (e) {
    }

    if (copied) {
      Notification.info(this.message);
    } else {
      Notification.error(
        'Can\'t copy citation. Please select text and press Ctrl+C.'
      );
    }
  }

  /**
   * Highlight text inside of div containing citation text
   */
  selectTargetValue() {
    if (this.target[0].nodeName === 'DIV') {
      const range = document.createRange();
      range.selectNodeContents(this.target[0]);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (this.target[0].nodeName === 'INPUT') {
      this.target.focus().select();
    }
  }

  /**
   * Method to be called for disabling / enabling copy button.
   * @param disableOrEnable: false is for enabling
   */
  disable(disableOrEnable) {
    this.trigger.prop('disabled', disableOrEnable).toggleClass('disabled', disableOrEnable);
  }
}