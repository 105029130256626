import Cookie from 'js-cookie';

export function setSecureCookieIfPossible(cookieName, cookieValue, attrs) {
  const cookieAttrs = attrs || {};
  if (window.location.protocol === 'https:') {
    cookieAttrs['secure'] = true;
  }
  if (!cookieAttrs['expires']) {
    cookieAttrs['expires'] = 365; // default expires is 1 year
  }
  Cookie.set(cookieName, cookieValue, cookieAttrs);
}

export function readCookie(cookieName) {
  return Cookies.get(cookieName);
}